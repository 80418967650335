<template>
<div class="product2 pl-4 pr-0 md:px-8">
  <div class="flex items-center">
    <img class="w-80px md:w-100px h-80px md:h-100px"
         :src="require('../../assets/product/' + info.img)">
    <p class="title">{{info.title}}</p>
  </div>
  <p class="describe" v-for="(item,i) in info.describe" :key="i">
    <span></span>{{item}}
  </p>
</div>
</template>

<script>
export default {
  name: "product2",
  props: ['info']
}
</script>

<style scoped lang="scss">
.product2 {
  background: #F4F6FF;
  border-radius: 20px;
  box-sizing: border-box;
  color: #333;
  text-align: left;
}

.title {
  font-weight: bold;
  font-size: 18px;
}

.describe {
  line-height: 30px;
  font-size: 16px;
  text-align: left!important;
  padding-left: 20px;

  span {
    display: inline-block;
    background: #2555FF;
    width: 10px;
    height: 10px;
    border-radius: 20px;
    margin-right: 10px;
  }
}

@media screen and ( min-width: 1280px) {
  .product2 {
    height: 186px;
    margin-bottom: 40px;
  }
}

@media screen and ( max-width: 1280px) {
  .product2 {
    height: auto;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .describe {
    padding-left: 0;
  }
}

@media screen and ( max-width: 640px ) {
  .product2 {
    height: auto;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .title {
    font-size: 16px;
  }

  .describe{
    font-size: 14px;
  }
}
</style>
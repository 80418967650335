<template>
<div class="product5">
  <div class="mx-auto"
       style="">
    <img class="max-w-full max-h-full"
         :src="require('../../assets/product/' + info.img)">
  </div>
  <p class="title">{{info.title}}</p>
  <p class="describe">{{info.describe}}</p>
</div>
</template>

<script>
export default {
  name: "product5",
  props: ['info']
}
</script>

<style scoped lang="scss">
.product5 {
  background: #F6F8FE;
  border-radius: 30px;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.title{
  color: #333;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  top: -6%;
}
.describe {
  color: #666;
  font-size: 16px;
  padding: 0 20%;
  line-height: 30px;
}

@media screen and ( min-width: 768px ) {
  .product5 {
    width: 380px;
    height: 423px;
  }

  div {
    width: 300px;
    height: 300px;
  }
}

@media screen and ( max-width: 768px) {
  .product5 {
    width: 48%;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  div {
    width: 70%;
  }

  .title {
    font-size: 16px;
  }
  .describe {
    font-size: 14px;
    line-height: 20px;
    padding: 0 5%;
  }
}
</style>
<template>
  <div class="product">
    <banner :card-back="cardBack"/>
    <div class="item">
      <div class="mx-auto xl:w-1200">
        <p class="title">行业痛点</p>
        <div class="flex justify-around flex-wrap">
          <div class="w-2/5 justify-around
               xl:w-1/5 xl:justify-between mb-8 xl:mb-0"
               v-for="(item,i) in item1" :key="i">
            <div class="mx-auto w-100px md:w-120px
                        xl:w-150px h-100px md:h-120px xl:h-150px">
              <img class="max-w-full max-h-full mx-auto" :src="require('../assets/product/' + item.img)">
            </div>
            <span class="iTitle">{{ item.title }}</span>
            <p class="iDescribe text-left">{{ item.describe }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="item">
      <div class="mx-auto xl:w-1200">
        <p class="title">方案价值</p>
        <div class="flex justify-around flex-wrap">
          <item2 class="w-full xl:w-48%"
                 style=""
                 v-for="(item,i) in item2"
                 :key="i" :info="item"></item2>
        </div>
      </div>
    </div>

    <div class="item">
      <div class="mx-auto xl:w-1200">
        <p class="title">六大服务体系</p>
        <div class="flex justify-around flex-wrap">
          <item3 class="w-full xl:w-48%"
                 v-for="(item,i) in item3"
                 :key="i" :info="item"></item3>
        </div>
      </div>
    </div>

    <div class="item">
      <div class="mx-auto xl:w-1200 hidden xl:block">
        <p class="title">可信服务</p>
        <div class="block xl:flex" style="font-size: 16px;line-height: 3vh;color: #666;">
          <div class="w-full -mr-0 xl:w-2/4 xl:mr-6 mx-auto">
            <img class="max-w-full max-h-full mx-auto"
                 src="../assets/product/4-1.png">
          </div>
          <div class="text-center xl:text-left w-full xl:w-2/4">
            <p style="color: #333;margin: 10% 0;">
              通过将各种高可用数据管理技术与业务进行精准整合，
              为各行各业提供定制化解决方案，
              实现对原有业务体系优化、整合、加强的可信化管理
            </p>
            <p v-for="(item,i) in item4" :key="i">
              <span class="blueDot"></span>{{item}}
            </p>
          </div>
        </div>
      </div>

      <div class="block xl:hidden">
        <p class="title">可信服务</p>
        <p style="color: #333;font-size: 16px;line-height: 30px;
          margin-bottom: 24px;text-align: left;">
          通过将各种高可用数据管理技术与业务进行精准整合，
          为各行各业提供定制化解决方案，
          实现对原有业务体系优化、整合、加强的可信化管理
        </p>
        <div class="flex justify-between items-center">
          <div class="w-2/4">
            <img class="max-w-full max-h-full mx-auto"
                 src="../assets/product/4-1.png">
          </div>
          <div class="w-2/4"
               style="font-size: 14px;line-height: 24px;color: #666;">
            <p v-for="(item,i) in item4" :key="i">
              <span class="blueDot"></span>{{item}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="item">
      <div class="mx-auto xl:w-1200">
        <p class="title">可信产品</p>
        <div class="flex justify-around flex-wrap xl:justify-between">
          <item5 class="w-380px"
                 v-for="(item,i) in item5"
                 :key="i" :info="item"></item5>
          <!--<item5 class="w-5/12 h-56 mb-6 xl:w-32% xl:h-88"
                 v-for="(item,i) in item5"
                 :key="i" :info="item"
                 style="width: 380px;height: 423px;"></item5>-->
        </div>
      </div>
    </div>

    <div class="item">
      <div class="mx-auto xl:w-1200 hidden xl:block">
        <p class="title">可信链网</p>
        <div class="block xl:flex justify-between">
          <div class="text-center xl:text-left w-full xl:w-5/12">
            <p style="color: #333;margin: 6% 0;">基于区块链技术，实现对业务的防篡改可信证实管理，强化和延展可信服务能力</p>
            <p v-for="(item,i) in item6" :key="i">
              <span class="blueDot"></span>{{item}}
            </p>
          </div>

          <div class="w-full xl:w-2/4">
            <img class="max-w-full max-h-full mx-auto" src="../assets/product/6-1.png">
          </div>
        </div>
      </div>

      <div class="block xl:hidden">
        <p class="title">可信链网</p>
        <p style="color: #333;font-size: 16px;line-height: 30px;
            margin-bottom: 24px;text-align: left;">基于区块链技术，实现对业务的防篡改可信证实管理，强化和延展可信服务能力</p>
        <div class="flex justify-between items-center">
          <div class="w-2/4">
            <img class="max-w-full max-h-full mx-auto"
                 src="../assets/product/6-1.png">
          </div>
          <div class="w-2/4">
            <p v-for="(item,i) in item6" :key="i"
               style="font-size: 14px;line-height: 24px;">
              <span class="blueDot"></span>{{item}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <foot/>
  </div>
</template>

<script>
import item2 from '@/components/product/product2'
import item3 from '@/components/product/product3'
import item5 from '@/components/product/product5'

import banner from '@/components/websiteHeader'
import foot from '@/components/websiteFooter'

export default {
  name: "Product",
  components: {
    item2,item3,item5,banner,foot
  },
  data() {
    return {
      cardBack: [
        {carouselOne: require('../assets/banner/产品与服务/产品与服务.jpg')},
      ],
      item1: [
        {title: '信息中心的权责问题',
          describe: '传统模式下，数据整合后有变化，信息中心只是IT系统管理方，无法承担数据内容真伪的校验任务',
          img: '1-1.png'},
        {title: '跨部门的政务数据可信问题',
          describe: '中心化系统存在数据篡改、造假的风险；数据集中整合后发生变化，各委办局不敢在关键业务上使用整合过的数据',
          img: '1-2.png'},
        {title: '信息泄露安全隐患',
          describe: '中心化系统有被入侵风险，导致信息泄露，存在信息安全隐患',
          img: '1-3.png'},
        {title: '系统稳定性难度大',
          describe: '中心化系统瘫痪导致整个服务不可用，如建设“两地三中心”带来成本大幅度提升',
          img: '1-4.png'},
      ],
      item2: [
        {
          title: '打破数据壁垒',
          describe: ['数据提供者具有管理权限', '参与方所有节点具有全部数据，随时间同步'],
          img: '2-1.png'
        },
        {
          title: '数据存证可溯源',
          describe: ['所有信息永久存证，不可篡改', '业务办理部门间过程数据全记录上链，责任清晰'],
          img: '2-2.png'},
        {
          title: '数据更为安全',
          describe: ['采用联盟链技术严格准入机制', '拥有严格的权限管理机制'],
          img: '2-3.png'
        },
        {
          title: '扩展性强',
          describe: ['随时可增删节点，灵活性较高'],
          img: '2-4.png'
        },
      ],
      item3: [
        {title: '统一身份授权及权限服务', img: '3-1.png'},
        {title: '统一密码服务', img: '3-2.png'},
        {title: '跨区域跨网络传输服务', img: '3-3.png'},
        {title: '分布式存证服务', img: '3-4.png'},
        {title: '统一数据证书服务', img: '3-5.png'},
        {title: '高可用组件服务', img: '3-6.png'},
      ],
      item4: ['数据可视管理','应用系统集成','业务结构优化','技术环境改进'],
      item5: [
        {title: '业务可信', describe: '以应用综合管理为核心 \n ' +
              '满足应用各业务的可信管理', img: '5-1.png'},
        {title: '可信溯源', describe: '基于可信链及业务监控\n' +
              '的数据可信溯源服务', img: '5-2.png'},
        {title: '可信追踪', describe: '基于可信链的\n' +
              '数据流向追踪服务', img: '5-3.png'},
        {title: '源点可信', describe: '兼容信任体系的各类\n' +
              '对象身份可信认证服务', img: '5-4.png'},
        {title: '数据可信', describe: '兼容数据、文件、图片、音视频等格式的防篡改可信服务', img: '5-5.png'},
        {title: '应用可信', describe: '以应用综合管理为核心\n' +
              '满足应用各业务的可信管理', img: '5-6.png'},
        {title: '权限可信', describe: '基于动态权限管理机制\n' +
              '的权限认证可信服务', img: '5-7.png'},
        {title: '传输可信', describe: '基于高速传输网络服务\n' +
              '提供安全可信保障', img: '5-8.png'},
        {title: '存储可信', describe: '满足数据中心、数据仓库等\n' +
              '多种存储环境的可信保障服务', img: '5-9.png'},
      ],
      item6: ['支持多版本链路环境','支持私有化专链部署','支持链数据价值治理','可配置访问权限管理','兼容链路间数据互信'],
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  font-size: 24px;
  padding: 80px;
}

.item {
  padding: 0 2vw 6vh;
}
.item:nth-child(odd) {
  background: #FBFBFD;
}

.blueDot {
  display: inline-block;
  background: #2555FF;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  margin-right: 10px;
}

.item:nth-child(2) {
  .iTitle {
    font-weight: bold;
    color: #333;
    font-size: 16px;
    height: 46px;
    display: block;
    margin-top: 10px;
  }
  .iDescribe {
    color: #555;
    font-size: 14px;
    line-height: 24px;
  }
}

.item:nth-child(7) {
  > div {
    > div {
      p {
        line-height: 36px;
        font-size: 16px;
        color: #666;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .title {
    padding: 30px;
    font-size: 20px;
  }

  .item {
    padding: 0 4vw 6vh;
  }

  .item:nth-child(2) {
    .iTitle {
      font-size: 14px;
    }
  }
}
</style>
<template>
  <div class="product3">
    <div class="left">
      <img class="max-w-full max-h-full" :src="require('../../assets/product/' + info.img)">
    </div>
    <div class="right">
      <p>{{info.title}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "product3",
  props: ['info']
}
</script>

<style scoped lang="scss">
.product3 {
  display: flex;
  box-shadow: 0px 10px 24px 0px rgba(37, 85, 255, 0.18);
}
img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.left {
  text-align: center;
  background: #E9EEFF;
  width: 100px;
  margin-right: 6%;
}

.right {
  color: #2555FF;
  font-size: 20px;

  p {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media screen and ( min-width: 768px) {
  .product3{
    height: 76px;
    margin-bottom: 55px;
  }
}
@media screen and ( max-width: 768px) {
  .product3{
    height: 60px;
    margin-bottom: 30px;
  }

  .left {
    width: 70px;
  }

  .right {
    font-size: 20px;
  }
}
</style>